
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  getCurrentInstance,
} from "vue";
import type { ElForm } from "element-plus";
import { Close, Check, EditPen } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus/lib/components";
import { recruitService } from "@/api";
export default defineComponent({
  props: {
    isDialog: Boolean,
    closeDialog: Function,
    isBatch: Boolean,
    accountId: String,
    expectForm: Object,
    successCallBack: Function,
    detail: Object,
  },
  setup(props) {
    const instance = getCurrentInstance();
    const { proxy } = instance as any;
    type FormInstance = InstanceType<typeof ElForm>;
    const ruleFormRef = ref<FormInstance>();
    const forwardLocationRef = ref<any>();
    const cascaderProps = {
      value: "id",
      label: "text",
      leaf: "aaa",
      checkStrictly: true,
    };
    const state = reactive<{
      form: any;
      salaryList: any;
      industryList: any;
      cityOptions: any;
      postList: any;
      isLoading: boolean;
      detail: any;
      formClone: any;
      isShowDetail: boolean;
    }>({
      isShowDetail: true,
      form: props.expectForm,
      detail: props.detail,
      formClone: JSON.parse(JSON.stringify(props.expectForm)),
      salaryList: [],
      industryList: [],
      cityOptions: [],
      postList: [],
      isLoading: false,
    });
    onMounted(() => {
      // 获取期望薪资列表
      recruitService.common
        .dictList("forward_salary")
        .then((val) => {
          state.salaryList = val.data.map((el: any) => {
            return { value: el.dictId, label: el.dictName };
          });
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
      // 获取期望行业列表
      recruitService.common
        .dictList("corp_industry")
        .then((val) => {
          state.industryList = val.data.map((el: any) => {
            return { value: el.dictId, label: el.dictName };
          });
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
      // 获取工作城市
      recruitService.common
        .cityTree()
        .then((val) => {
          state.cityOptions = val.data;
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
      // 获取期望职位
      recruitService.post
        .postList({
          pageIndex: -1,
        })
        .then((val) => {
          state.postList = val.data.records.map((el: any) => {
            return {
              label: el.postName,
              value: el.postId,
            };
          });
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    });
    function getSelectText(value: any, arr: any) {
      const text = ((arr as any[]) || []).find((d) => d.value === value)?.text;
      return text;
    }
    const checkMaxSalary = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error("请输入薪资最高要求！"));
      } else if (
        parseInt(getSelectText(value, state.salaryList)) <
        parseInt(getSelectText(state.form.forwardMinSalary, state.salaryList))
      ) {
        callback(new Error("薪资最高要求不能低于薪资最低要求!"));
      } else {
        callback();
      }
    };
    const checkMinSalary = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error("请输入薪资最高要求！"));
      } else if (
        parseInt(getSelectText(value, state.salaryList)) >
        parseInt(getSelectText(state.form.forwardMaxSalary, state.salaryList))
      ) {
        callback(new Error("薪资最高要求不能低于薪资最低要求!"));
      } else {
        callback();
      }
    };
    const rules = {
      forwardLocationIdList: [
        {
          required: true,
          message: "请选择工作城市",
          trigger: "blur",
        },
      ],
      forwardPostName: [
        {
          required: true,
          message: "请输入期望职位",
          trigger: "blur",
        },
      ],
      forwardPostType: [
        {
          required: true,
          message: "请选择期望行业",
          trigger: "blur",
        },
      ],
      forwardMinSalary: [
        {
          validator: checkMinSalary,
          trigger: "blur",
        },
      ],
      forwardMaxSalary: [
        {
          validator: checkMaxSalary,
          trigger: "blur",
        },
      ],
    };
    const submitForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          if (!state.isLoading) {
            state.form["forwardLocation"] =
              proxy.$refs["forwardLocationRef"].getCheckedNodes()[0].pathLabels;
            state.form["forwardLocation"] =
              state.form["forwardLocation"].join("/");
            state.form["forwardLocationId"] =
              state.form["forwardLocationIdList"].join(",");
            state.isLoading = true;
            recruitService.user
              .updateForward(state.form)
              .then((val) => {
                ElMessage.success("保存成功！");
                state.detail = val.data;
                if (props.successCallBack) {
                  props.successCallBack(val.data);
                }
                state.isLoading = false;
              })
              .catch((val) => {
                state.isLoading = false;
                if (val.isHttpStatus) {
                  console.log(val.statusText);
                } else {
                  ElMessage.error(val.message || "错误");
                }
              });
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    function closeDialogCpt() {
      if (props.isBatch) {
        state.form = JSON.parse(JSON.stringify(state.formClone));
        state.isShowDetail = true;
      }
      if (props.closeDialog) {
        props.closeDialog();
      }
    }
    return {
      ...toRefs(state),
      cascaderProps,
      forwardLocationRef,
      submitForm,
      EditPen,
      rules,
      ruleFormRef,
      Close,
      Check,
      closeDialogCpt,
      editForm() {
        state.isShowDetail = false;
      },
    };
  },
});
