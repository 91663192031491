
import {
  defineComponent,
  computed,
  reactive,
  toRefs,
  ref,
  onMounted,
} from "vue";
import type { ElForm } from "element-plus";
import {
  Delete,
  Close,
  EditPen,
  Check,
  CirclePlus,
} from "@element-plus/icons-vue";
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
import utils from "@/utils/util";
export default defineComponent({
  props: {
    isDialog: Boolean,
    closeDialog: Function,
    successCallBack: Function,
    isBatch: Boolean,
    accountId: String,
    detailData: Object,
    detail: Object,
  },
  setup(props) {
    type FormInstance = InstanceType<typeof ElForm>;
    // const ruleFormRef = ref<FormInstance>();
    const isAllDetail = computed(() => {
      let AllItemDetail = true;
      state.formData.formArr.forEach((element: any) => {
        if (!element.isShowDetail) {
          AllItemDetail = false;
        }
      });
      return AllItemDetail;
    });
    const state = reactive<{
      formData: any;
      ruleFormRef: any;
      isLoading: boolean;
      detail: any;
      formDataClone: any;
    }>({
      formData: {
        formArr: [
          // {
          //   projectExpId: null,
          //   projectName: "",
          //   projectRole: "",
          //   daterange: "",
          //   content: "",
          //   guid: utils.guid(),
          // },
        ],
      },
      formDataClone: null,
      detail: { projectExpList: [] },
      ruleFormRef: ref<FormInstance>(),
      isLoading: false,
    });
    onMounted(() => {
      if (props.detailData?.formArr && props.detailData.formArr.length > 0) {
        state.formData = props.detailData;
        state.formDataClone = JSON.parse(JSON.stringify(state.formData));
      }
      if (
        props.isDialog &&
        props.detailData?.formArr &&
        props.detailData.formArr.length === 0
      ) {
        state.formData = {
          formArr: [
            {
              projectExpId: null,
              projectName: "",
              projectRole: "",
              daterange: "",
              content: "",
              guid: utils.guid(),
            },
          ],
        };
      }
      state.detail = props.detail;
    });
    const submitForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          if (!state.isLoading) {
            state.formData.formArr.forEach((element: any) => {
              element["startDate"] = utils.strFmTimestamp(
                element["daterange"][0]
              );
              element["endDate"] = utils.strFmTimestamp(
                element["daterange"][1]
              );
            });
            state.isLoading = true;
            if (props.isBatch) {
              const obj = {
                accountId: props.accountId,
                expList: state.formData.formArr,
              };
              recruitService.user
                .saveProjectBatch(obj)
                .then((val) => {
                  ElMessage.success("保存成功！");
                  state.detail = val.data;
                  const arr: any = [];
                  val.data.projectExpList.forEach((element: any) => {
                    const daterange = [
                      utils.dateFm(element.startDate, "YYYY-MM-DD"),
                      utils.dateFm(element.endDate, "YYYY-MM-DD"),
                    ];
                    arr.push({
                      isShowDetail: true,
                      guid: utils.guid(),
                      projectName: element.projectName,
                      daterange: daterange,
                      projectRole: element.projectRole,
                      content: element.content,
                      projectExpId: element.projectExpId,
                    });
                  });
                  state.formData.formArr = arr;
                  state.formDataClone = JSON.parse(
                    JSON.stringify(state.formData)
                  );
                  if (props.successCallBack) {
                    props.successCallBack(val.data);
                  }
                  state.isLoading = false;
                })
                .catch((val) => {
                  state.isLoading = false;
                  if (val.isHttpStatus) {
                    console.log(val.statusText);
                  } else {
                    ElMessage.error(val.message || "错误");
                  }
                });
            } else {
              const obj = state.formData.formArr[0];
              obj.accountId = props.accountId;
              recruitService.user
                .saveProject(obj)
                .then((val) => {
                  ElMessage.success("保存成功！");
                  if (props.successCallBack) {
                    props.successCallBack(val.data);
                  }
                  state.isLoading = false;
                })
                .catch((val) => {
                  state.isLoading = false;
                  if (val.isHttpStatus) {
                    console.log(val.statusText);
                  } else {
                    ElMessage.error(val.message || "错误");
                  }
                });
            }
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    function addForm() {
      state.formData.formArr.push({
        projectExpId: null,
        projectName: "",
        projectRole: "",
        daterange: "",
        content: "",
        guid: utils.guid(),
      });
    }
    function deleteForm(index: number) {
      if (state.formData.formArr[index].projectExpId) {
        recruitService.user
          .deleteProject(state.formData.formArr[index])
          .then(() => {
            ElMessage.success("删除成功！");
            state.formData.formArr.splice(index, 1);
            state.formDataClone.formArr.splice(index, 1);
            state.detail.projectExpList.splice(index, 1);
          })
          .catch((val) => {
            if (val.isHttpStatus) {
              console.log(val.statusText);
            } else {
              ElMessage.error(val.message || "错误");
            }
          });
      } else {
        state.formData.formArr.splice(index, 1);
        state.formDataClone.formArr.splice(index, 1);
        state.detail.projectExpList.splice(index, 1);
      }
    }
    function closeDialogCpt() {
      if (props.isBatch) {
        const arr: any = [];
        if (state.formDataClone) {
          state.formDataClone.formArr.forEach((element: any) => {
            if (element.projectExpId) {
              element.isShowDetail = true;
              arr.push(element);
            }
          });
          state.formData.formArr = arr;
          state.formDataClone = JSON.parse(JSON.stringify(state.formData));
        } else {
          state.formData.formArr = [];
        }
      }
      if (props.closeDialog) {
        props.closeDialog();
      }
    }
    function edit(index: number) {
      state.formData.formArr[index].isShowDetail = false;
    }

    return {
      ...toRefs(state),
      submitForm,
      addForm,
      deleteForm,
      EditPen,
      Delete,
      CirclePlus,
      Close,
      Check,
      closeDialogCpt,
      edit,
      isAllDetail,
      //   Delete,
      //   ruleFormRef,
    };
  },
});
