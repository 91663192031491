
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  props: {
    resumeUrl: String,
  },
  setup(props) {
    const state: any = reactive<{
      [x: string]: any;
    }>({
      resumeUrl: props.resumeUrl,
    });

    return {
      ...toRefs(state),
    };
  },
});
