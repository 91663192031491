
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  getCurrentInstance,
  watch,
} from "vue";
import type { ElForm } from "element-plus";
import utils from "@/utils/util";
import { Close, Check } from "@element-plus/icons-vue";
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
import { uploadAction } from "@/api/common";
import { useStore } from "vuex";
import { sessionStorage } from "@/utils/util";
export default defineComponent({
  props: {
    isDialog: Boolean,
    basicInfoForm: Object,
    closeDialog: Function,
    isBatch: Boolean,
    accountId: String,
    setBasicInfo: Function,
    successCallBack: Function,
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const { proxy } = instance as any;
    const uploadRef = ref<any>();
    const hukouAddr = ref<any>();
    const currentAddr = ref<any>();
    type FormInstance = InstanceType<typeof ElForm>;
    const ruleFormRef = ref<FormInstance>();
    const checkMobile = (rule: any, value: any, callback: any) => {
      if (!/^1[123456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
        return;
      }
      callback();
    };
    const checkLength = (rule: any, value: any, callback: any) => {
      if (value.length > 500) {
        callback(new Error("最多500个字符"));
        return;
      } else {
        callback();
      }
    };
    const rules = {
      accountName: [
        {
          required: true,
          message: "请输入姓名",
          trigger: "blur",
        },
      ],
      sex: [
        {
          required: true,
          message: "请选择性别",
          trigger: "blur",
        },
      ],
      mobile: [
        {
          validator: checkMobile,
          trigger: "blur",
        },
        {
          required: true,
          message: "请输入手机号",
          trigger: "blur",
        },
      ],
      email: [
        {
          type: "email",
          message: "请输入正确的邮箱地址",
          trigger: "blur",
        },
      ],
      // age: [
      //   {
      //     required: true,
      //     message: "请输入年龄",
      //     trigger: "blur",
      //   },
      // ],
      birthTime: [
        {
          required: true,
          message: "请选择出生日期",
          trigger: "blur",
        },
      ],
      startWorkDate: [
        {
          required: true,
          message: "请选择参加工作时间",
          trigger: "blur",
        },
      ],
      lastWorkUnit: [
        {
          required: true,
          message: "请输入最近工作单位",
          trigger: "blur",
        },
      ],
      selfEvaluate: [
        {
          validator: checkLength,
          trigger: ["blur", "change"],
        },
      ],
    };
    const cascaderProps = {
      value: "id",
      label: "text",
      leaf: "aaa",
    };
    const state = reactive<{
      action: any;
      form: any;
      autoSize: any;
      options: any;
      cityOptions: any;
      avatar: any;
      isLoading: boolean;
    }>({
      action: uploadAction,
      form: props.basicInfoForm,
      autoSize: { minRows: 4, maxRows: 6 },
      options: [
        { id: 1, text: "离职-随时到岗" },
        { id: 2, text: "招聘流程中" },
        { id: 3, text: "在职-暂不考虑" },
        { id: 4, text: "在职-考虑机会" },
        { id: 5, text: "在职-月内到岗" },
      ],
      cityOptions: [],
      avatar:
        "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png",
      isLoading: false,
    });
    onMounted(() => {
      if (props.basicInfoForm?.headUrl) {
        state.avatar = props.basicInfoForm.headUrl;
      }
      recruitService.common
        .regionList()
        .then((val) => {
          state.cityOptions = val.data;
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
      watch(
        () => state.form.idCard,
        (val) => {
          let birthTime = "";
          if (val != null && val != "") {
            if (val.length === 18) {
              birthTime = val.substr(6, 8);
              state.form.birthTime = birthTime.replace(
                /(.{4})(.{2})/,
                "$1-$2-"
              );
              state.form.birth = utils.strFmTimestamp(birthTime);
              if (parseInt(val.substr(16, 1)) % 2 == 1) {
                state.form.sex = 1;
              } else {
                state.form.sex = 0;
              }
            }
          }
        }
      );
      watch(
        () => state.form.birthTime,
        (val) => {
          if (val != null && val != "") {
            const now = new Date().getTime() / 1000;
            const age = Math.ceil((now - utils.strFmTimestamp(val)) / 31536000);
            state.form.age = age;
          }
        }
      );
    });
    const submitForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          if (!state.isLoading) {
            state.form.birth = utils.strFmTimestamp(state.form.birthTime);
            state.form.startWorkTime = utils.strFmTimestamp(
              state.form.startWorkDate
            );
            if (
              state.form["currentCode"] &&
              state.form["currentCode"].length > 0
            ) {
              [
                state.form.currentProvCode,
                state.form.currentCityCode,
                state.form.currentDistrictCode,
              ] = state.form["currentCode"];
              [
                state.form.currentProvName,
                state.form.currentCityName,
                state.form.currentDistrictName,
              ] = proxy.$refs["currentAddr"].getCheckedNodes()[0].pathLabels;
            }
            if (state.form["hukouCode"] && state.form["hukouCode"].length > 0) {
              [
                state.form.hukouProvCode,
                state.form.hukouCityCode,
                state.form.hukouDistrictCode,
              ] = state.form["hukouCode"];
              [
                state.form.hukouProvName,
                state.form.hukouCityName,
                state.form.hukouDistrictName,
              ] = proxy.$refs["hukouAddr"].getCheckedNodes()[0].pathLabels;
            }
            state.isLoading = true;
            if (!props.isBatch) {
              recruitService.user
                .updateBase(state.form)
                .then((val) => {
                  ElMessage.success("保存成功！");
                  if (props.successCallBack) {
                    props.successCallBack(val.data);
                  }
                  if (props.setBasicInfo) {
                    props.setBasicInfo(val.data);
                  }
                  state.isLoading = false;
                })
                .catch((val) => {
                  state.isLoading = false;
                  if (val.isHttpStatus) {
                    console.log(val.statusText);
                  } else {
                    ElMessage.error(val.message || "错误");
                  }
                });
            } else {
              recruitService.user
                .addOne(state.form)
                .then((val) => {
                  state.form.accountId = val.data.accountId;
                  if (!sessionStorage.get("accountId")) {
                    store.dispatch("setAccountId", val.data.accountId);
                    sessionStorage.set("accountId", val.data.accountId);
                  }
                  ElMessage.success("保存成功！");
                  if (props.successCallBack) {
                    props.successCallBack(val.data);
                  }
                  if (props.setBasicInfo) {
                    props.setBasicInfo(val.data);
                  }
                  state.isLoading = false;
                })
                .catch((val) => {
                  state.isLoading = false;
                  if (val.isHttpStatus) {
                    console.log(val.statusText);
                  } else {
                    ElMessage.error(val.message || "错误");
                  }
                });
            }
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    function handleSuccess(response: any, file: any, fileList: any[]) {
      if (response.code === 0) {
        file.name = file.name.replace(/,/g, "_");
        file.url = response.data;
        state.avatar = response.data;
        state.form.headUrl = response.data;
        // uploadRef.value.clearFiles();
        proxy.$refs.uploadRef.clearFiles();
        // const mainImg = proxy.$refs.uploadRef;
        // if (mainImg && mainImg.length) {
        //   mainImg.forEach((item: any) => {
        //     // item.uploadFiles.length = 0;
        //     item.clearFiles();
        //   });
        // }
      } else {
        const index = fileList.findIndex((d) => d.uid === file.uid);
        if (~index) {
          fileList.splice(index, 1);
        }
        ElMessage.error(response.message || "错误");
      }
      // state.fileList = fileList;
      // state.isLoading = false;
    }
    function getFormData() {
      submitForm(ruleFormRef.value);
    }
    return {
      ...toRefs(state),
      uploadRef,
      currentAddr,
      hukouAddr,
      submitForm,
      rules,
      ruleFormRef,
      getFormData,
      Check,
      Close,
      cascaderProps,
      handleSuccess,
    };
  },
});
